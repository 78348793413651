import React, { useState, useEffect } from "react";

const WebWidgetButton = ({ color = "#5ACFC9", title, initialMessage = "Hello! How can I help you today?" }) => {
  const [isLoading, setIsLoading] = useState(false);
  
  // Function to show a guide tooltip pointing to where the chat widget is located
  const showWidgetGuide = () => {
    // Create tooltip container
    const tooltip = document.createElement('div');
    tooltip.style.position = 'fixed';
    tooltip.style.bottom = '100px';
    tooltip.style.right = '30px';
    tooltip.style.backgroundColor = 'white';
    tooltip.style.color = '#333';
    tooltip.style.padding = '12px 18px';
    tooltip.style.borderRadius = '8px';
    tooltip.style.boxShadow = '0 4px 20px rgba(0,0,0,0.25)';
    tooltip.style.zIndex = '9998'; // High but below the widget
    tooltip.style.maxWidth = '300px';
    tooltip.style.border = '3px solid #5ACFC9';
    tooltip.style.fontSize = '16px';
    tooltip.style.fontWeight = 'bold';
    tooltip.style.pointerEvents = 'none'; // Make tooltip non-interactive
    
    // Create animated arrow pointing to bottom right (typical widget location)
    const arrow = document.createElement('div');
    arrow.style.position = 'absolute';
    arrow.style.bottom = '-25px';
    arrow.style.right = '20px';
    arrow.style.width = '30px';
    arrow.style.height = '30px';
    arrow.innerHTML = '⬇️';
    arrow.style.fontSize = '30px';
    arrow.style.pointerEvents = 'none'; // Make arrow non-interactive
    
    // Visible spotlight circle - we'll ensure it doesn't block clicks
    const spotlight = document.createElement('div');
    spotlight.style.position = 'fixed';
    spotlight.style.bottom = '20px';
    spotlight.style.right = '20px';
    spotlight.style.width = '70px';
    spotlight.style.height = '70px';
    spotlight.style.borderRadius = '50%';
    spotlight.style.border = '3px dashed #5ACFC9';
    spotlight.style.zIndex = '9996'; // Below widget
    spotlight.style.pointerEvents = 'none'; // Critical: allows clicks to pass through
    
    // Create tooltip text
    const text = document.createElement('div');
    text.innerHTML = 'Look for the chat widget here! 👇';
    
    tooltip.appendChild(text);
    tooltip.appendChild(arrow);
    document.body.appendChild(tooltip);
    document.body.appendChild(spotlight);
    
    // Add stylesheet for animations
    const style = document.createElement('style');
    style.textContent = `
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
        40% {transform: translateY(-10px);}
        60% {transform: translateY(-5px);}
      }
      
      @keyframes pulse {
        0% { 
          transform: scale(0.95);
          opacity: 0.7;
          border-color: #5ACFC9;
        }
        50% { 
          transform: scale(1.05);
          opacity: 1;
          border-color: #5ACFC9;
        }
        100% { 
          transform: scale(0.95);
          opacity: 0.7;
          border-color: #5ACFC9;
        }
      }
      
      @keyframes slideIn {
        from { transform: translateY(20px); opacity: 0; }
        to { transform: translateY(0); opacity: 1; }
      }
      
      @keyframes fadeOut {
        from { opacity: 1; }
        to { opacity: 0; }
      }
      
      @keyframes wiggle {
        0%, 100% { transform: rotate(0deg) scale(1); }
        25% { transform: rotate(-5deg) scale(1.1); }
        75% { transform: rotate(5deg) scale(1.1); }
      }
    `;
    document.head.appendChild(style);
    
    // Apply animations
    tooltip.style.animation = 'slideIn 0.3s ease-out forwards';
    arrow.style.animation = 'bounce 1s infinite';
    spotlight.style.animation = 'pulse 2s infinite';
    
    // Find and animate the actual chat widget icon if it exists
    const widgetIcon = document.querySelector('.chat-icon');
    if (widgetIcon) {
      widgetIcon.style.animation = 'wiggle 1s infinite';
    }
    
    // Remove elements after 7 seconds
    setTimeout(() => {
      tooltip.style.animation = 'fadeOut 0.3s ease-in forwards';
      spotlight.style.animation = 'fadeOut 0.3s ease-in forwards';
      
      if (widgetIcon) {
        widgetIcon.style.animation = '';
      }
      
      setTimeout(() => {
        tooltip.remove();
        spotlight.remove();
        style.remove();
      }, 300);
    }, 7000);
  };

  // NEW FUNCTION: Send initial message and open widget
  const sendInitialMessage = () => {
    // Check if the chat widget API is available in the window
    if (window.ChatWidget && typeof window.ChatWidget.sendMessage === 'function') {
      // First open the widget
      if (typeof window.ChatWidget.open === 'function') {
        window.ChatWidget.open();
      }
      
      // Then send the initial message
      window.ChatWidget.sendMessage(initialMessage);
      
      // Log success
      console.log("Initial message sent to chat widget");
    } else {
      console.warn("Chat widget API not available or missing sendMessage method");
    }
  };

  const handleClick = () => {
    if (isLoading) return; // Prevent multiple clicks
    setIsLoading(true);
    
    // Load the script
    const loadAndShowGuide = () => {
      if (!document.querySelector('script[src="https://d19fkfslnwxv8r.cloudfront.net/chat-widget-v1.js"]')) {
        const script = document.createElement('script');
        script.src = "https://d19fkfslnwxv8r.cloudfront.net/chat-widget-v1.js";
        script.setAttribute('data-api-key', 'rswILkrw7SXtjMryEZLxecVjb0pEFRTbuOExvFRpSERrokMkJfxLTuWMJ9WUtqEap8GAebGbzO5dvHWxtBAfKp7QJpA3c2lK26kfYtmfARYv4UlJTObKSLOEdr4ETtvS');
        script.setAttribute('data-title', 'operaitor Chat');
        script.setAttribute('data-primary-color', '#5ACFC9');
        script.setAttribute('data-auto-open', 'true');
        
        // Add load event listener
        script.onload = () => {
          // After script loads, show guide immediately, then check for widget
          showWidgetGuide();
          
          // Wait a bit to make sure elements are in DOM
          setTimeout(() => {
            // Check for widget
            const checkWidgetInterval = setInterval(() => {
              const widgetIcon = document.querySelector('.chat-icon');
              if (widgetIcon) {
                clearInterval(checkWidgetInterval);
                
                // Apply animation directly to the widget
                widgetIcon.style.animation = 'wiggle 1s infinite';
                
                // After a short delay, send the initial message and open widget
                setTimeout(() => {
                  sendInitialMessage();
                }, 1000);
              }
            }, 100);
            
            // Stop checking after a while
            setTimeout(() => {
              clearInterval(checkWidgetInterval);
              setIsLoading(false);
            }, 2000);
          }, 500);
        };
        
        script.onerror = () => {
          setIsLoading(false);
          alert("Chat widget could not be loaded. Please check your internet connection and try again.");
        };
        
        document.body.appendChild(script);
      } else {
        // If script is already loaded, just show the guide and send message
        showWidgetGuide();
        
        // Wait a short time then try to send the message
        setTimeout(() => {
          sendInitialMessage();
          setIsLoading(false);
        }, 500);
      }
    };
    
    // Slight delay to ensure button state changes are visible
    setTimeout(loadAndShowGuide, 100);
  };
  
  return (
    <button
      onClick={handleClick}
      disabled={isLoading}
      className="flex items-center justify-center gap-1 px-4 py-3 rounded-full font-medium transition-all group relative overflow-hidden"
      style={{
        backgroundColor: 'white',
        color: color,
        border: `2px solid ${color}`,
        boxShadow: `0 4px 14px ${color}30`
      }}
    >
      {/* Background hover effect */}
      <span 
        className="absolute inset-0 w-0 bg-gradient-to-r group-hover:w-full transition-all duration-300 ease-out opacity-20"
        style={{ background: color }}
      ></span>
      
      {/* Button icon with animation */}
      <span className="relative flex items-center justify-center w-6 h-6 rounded-full bg-white group-hover:scale-110 transition-transform duration-300">
        {isLoading ? (
          <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke={color} strokeWidth="4"></circle>
            <path className="opacity-75" fill={color} d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        ) : (
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-4 w-4 group-hover:rotate-12 transition-transform duration-300" 
            viewBox="0 0 20 20" 
            fill={color}
            style={{ filter: `drop-shadow(0 1px 1px ${color}40)` }}
          >
            <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
            <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
          </svg>
        )}
      </span>
      
      {/* Button text with subtle animation */}
      <span className="relative text-sm font-semibold group-hover:translate-x-1 transition-transform duration-200">
        {isLoading ? "Loading Chat..." : "Try Web Chat"}
      </span>
      
      {/* Subtle right arrow that appears on hover */}
      <span className="absolute right-4 opacity-0 group-hover:opacity-100 transform translate-x-2 group-hover:translate-x-0 transition-all duration-300">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </span>
    </button>
  );
};

export default WebWidgetButton;