import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  IconButton,
  MobileNav,
  Navbar,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import logo from "./assets/logo.jpeg";

function Navigation() {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <Navbar className="mx-auto max-w-7xl border-none shadow-none py-2 px-0 lg:px-0 lg:py-0">
      {/* Removed padding to allow more edge-to-edge spacing */}
      <div className="w-full flex items-center justify-between text-black px-0">
        <Typography
          as="a"
          href="/"
          className="mr-4 cursor-pointer py-1.5 font-medium pl-2 md:pl-0"
        >
          <img
            src={logo}
            className="h-10 w-auto hover:scale-105 transition-transform duration-300 ease-in-out"
            alt="Logo"
            // Added left padding on mobile, removed on larger screens
          />
        </Typography>
        <Button
          onClick={() => navigate("/login")}
          variant="gradient"
          size="sm"
          className="text-white hidden w-30 h-8 lg:inline-block bg-[#26add0] hover:bg-[#4bc0de] mr-2 md:mr-0"
          style={{
            boxShadow: "none",
            border: "none",
            paddingBottom: "4px",
            paddingTop: "4px",
          }}
        >
          <span className="text-sm">Log In</span>
        </Button>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {/* Icon SVGs */}
        </IconButton>
      </div>
      <MobileNav open={openNav}>
        <div className="container mx-auto">
          <Button
            onClick={() => navigate("/login")}
            variant="gradient"
            size="sm"
            fullWidth
            className="btn-sm text-white-200 bg-[#26add0] hover:bg-[#84d0df] w-full my-2"
          >
            <span>Log In</span>
          </Button>
        </div>
      </MobileNav>
    </Navbar>
  );
}

export default Navigation;