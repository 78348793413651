import React from "react";

const VapiContactButton = ({ assistantId, color, title, mode = "call" }) => {
  const isTextMode = mode === "text";
  
  const handleClick = () => {
    // Debug logging to verify the props being passed
    console.log("VapiContactButton received:", {
      assistantId,
      color,
      title,
      mode
    });
    
    // Store the props in sessionStorage before navigating
    const formProps = {
      assistantId: assistantId,
      phoneNumberId: "644d949b-65e7-4ad3-93ce-9be20a620006",
      color: color || "#26add0",
      title: title || (isTextMode ? "Request a Text Message" : "Request a Phone Call"),
      mode: mode // Add the mode to determine text vs call in the form
    };
    
    // Save to sessionStorage - ensure we're using the key that PhoneCallSection is looking for
    sessionStorage.setItem("callFormProps", JSON.stringify(formProps));
    
    // Custom event to notify the PhoneCallSection component of the change
    const modeChangeEvent = new CustomEvent('contactModeChanged', { 
      detail: { formProps } 
    });
    window.dispatchEvent(modeChangeEvent);
    
    // Scroll to the contact section
    const contactSection = document.getElementById("phone-call-section");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <button
      onClick={handleClick}
      className="flex items-center justify-center gap-1 px-4 py-3 rounded-full font-medium transition-all group relative overflow-hidden"
      style={{
        backgroundColor: 'white',
        color: color,
        border: `2px solid ${color}`,
        boxShadow: `0 4px 14px ${color}30`
      }}
    >
      {/* Background hover effect */}
      <span 
        className="absolute inset-0 w-0 bg-gradient-to-r group-hover:w-full transition-all duration-300 ease-out opacity-20"
        style={{ background: color }}
      ></span>
      
      {/* Button icon with animation - Phone for call, Message for text */}
      <span className="relative flex items-center justify-center w-6 h-6 rounded-full bg-white group-hover:scale-110 transition-transform duration-300">
        {isTextMode ? (
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-4 w-4 group-hover:rotate-12 transition-transform duration-300" 
            viewBox="0 0 20 20" 
            fill={color}
            style={{ filter: `drop-shadow(0 1px 1px ${color}40)` }}
          >
            <path d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2h-5l-5 5v-5H4a2 2 0 01-2-2V5z" />
          </svg>
        ) : (
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-4 w-4 group-hover:rotate-12 transition-transform duration-300" 
            viewBox="0 0 20 20" 
            fill={color}
            style={{ filter: `drop-shadow(0 1px 1px ${color}40)` }}
          >
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
        )}
      </span>
      
      {/* Button text with subtle animation */}
      <span className="relative text-sm font-semibold group-hover:translate-x-1 transition-transform duration-200">
        {isTextMode ? "Get a Text From AI" : "Get a Call By AI"}
      </span>
      
      {/* Subtle right arrow that appears on hover */}
      <span className="absolute right-4 opacity-0 group-hover:opacity-100 transform translate-x-2 group-hover:translate-x-0 transition-all duration-300">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </span>
    </button>
  );
};

export default VapiContactButton;