import React, { useRef } from "react";
import TitleSection from "./components/TitleSection";
import Footer from "./components/Footer";
import ValueProp from "./components/ValueProp";
import PhoneCallSection from "./components/PhoneCallComponent";

function Home({ refs }) {
  const { 
    privacyRef, 
    testimonialRef, 
    faqRef, 
    featureRef, 
    howitworksRef 
  } = refs;
  
  // Create a new ref for the phone call section
  const phoneCallSectionRef = useRef(null);

  return (
    <div>
      <TitleSection />
      <ValueProp />
      <PhoneCallSection ref={phoneCallSectionRef} />
      <Footer />
    </div>
  );
}

export default Home;