import React, { useEffect, useRef, useState } from "react";
import "../styles/animation.css";
import "../styles/ValuePropSection.css"
import NewPatientImage from "../assets/valueprop-call.png";
import AppointmentReminderImage from "../assets/reminder-text.png"
import CustomerSatisfaction from "../assets/satisfaction.png"
import RoutineImage from "../assets/valueprop-routine.png"
import VapiWebTestButton from './VapiWebTestButton';
import VapiContactButton from "./ContactButton";
import WebWidgetButton from './WebWidgetButton';


// Animation Only Progress Component (no visual bar)
const ProgressBar = ({ type, color }) => {
  const [currentValue, setCurrentValue] = useState(type === "decrease" ? 35 : 2);
  
  // Animate the number changing
  useEffect(() => {
    // Set initial value based on type
    setCurrentValue(type === "decrease" ? 35 : 2);
    
    // Create a smooth animation for the number changing
    let start = type === "decrease" ? 35 : 2;
    const end = type === "decrease" ? 5 : 30;
    const duration = 1500; // 1.5 seconds
    const interval = 50; // Update every 50ms
    const steps = duration / interval;
    const increment = (end - start) / steps;
    
    // Start animation after a small delay
    const timer = setTimeout(() => {
      const animation = setInterval(() => {
        if ((increment > 0 && start >= end) || (increment < 0 && start <= end)) {
          clearInterval(animation);
          setCurrentValue(end); // Ensure final value is exact
        } else {
          start += increment;
          setCurrentValue(Math.round(start * 10) / 10); // Round to 1 decimal place
        }
      }, interval);
      
      return () => clearInterval(animation);
    }, 200);
    
    return () => clearTimeout(timer);
  }, [type]);
  
  return (
    <div className="w-full max-w-md mx-auto">
      <div className="relative mb-8">
        {/* Just show the before/after text and values */}
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-center">
            <span className="text-2xl font-bold text-gray-700">
              {type === "decrease" ? "35%" : "2%"}
            </span>
            <span className="text-sm text-gray-500">
              {type === "decrease" ? "No-show rate" : "Conversion rate"}
            </span>
          </div>
          
          {/* Arrow */}
          <div className="flex-1 px-4 flex justify-center">
            <div className="h-0.5 w-full bg-gray-300 relative flex items-center">
              <svg className="w-8 h-8 text-gray-400 absolute" style={{ right: "-12px" }} viewBox="0 0 24 24" fill="none">
                <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
          
          <div className="flex flex-col items-center">
            <span className="text-3xl font-bold" style={{ color }}>
              {currentValue}%
            </span>
            <span className="text-sm" style={{ color }}>
              With our solution
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Highlight text component for highlighting key words
const HighlightText = ({ children, color }) => {
  return (
    <span className="relative inline">
      <span className="relative z-10">{children}</span>
      <span 
        className="absolute bottom-0 left-0 w-full h-[0.4em] z-0 opacity-30 rounded-sm" 
        style={{ backgroundColor: color }}
      ></span>
    </span>
  );
};

// Helper function to lighten a color
const lightenColor = (color, percent) => {
  // Simple implementation - replace with a proper color manipulation function in production
  const lighterColors = {
    "#26add0": "#5bc1d5",
    "#32c5e6": "#76d7e9",
    "#3edcf7": "#7fe7f9"
  };
  return lighterColors[color] || "#5bc1d5";
};

// Single feature/step bullet – now uses numbering instead of checkmarks
const FeaturePoint = ({ text, color, index }) => {
  return (
    <div 
      className="flex items-start mb-3 opacity-0 animate-fadeIn"
      style={{ animationDelay: `${index * 0.3}s` }} // Stagger the fade-in
    >
      <div className="mr-2 mt-0.5 text-lg font-bold" style={{ color }}>
        {index + 1}.
      </div>
      <div>
        <span className="font-medium" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
};


const ValuePropsSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const sectionRef = useRef(null);
  const indicatorRef = useRef(null);
  const navItemsRef = useRef([]);
  const containerRef = useRef(null);
  const cardsContainerRef = useRef(null);

  // Helper functions for card positioning and opacity
  const getCardOpacity = (index, activeIndex) => {
    const distance = Math.abs(index - activeIndex);
    if (distance === 0) return 1;
    if (distance === 1) return 0.5; // More subtle background cards
    if (distance === 2) return 0.2; 
    return 0;
  };
  
  // Calculate card transform based on position relative to active index
  const getCardTransform = (index, activeIndex) => {
    const distance = index - activeIndex;
    if (distance === 0) {
      // Active card - bring it forward in 3D space
      return 'translateY(0) scale(1) translateZ(100px)';
    } else if (distance < 0) {
      // Cards before active (slide up and back)
      // Push further back in Z space to prevent overlapping active card
      return `translateY(${distance * -60}px) scale(0.92) translateZ(-50px)`;
    } else {
      // Cards after active (slide down and forward)
      // Push back in Z space but not as far as the ones above
      return `translateY(${distance * 100}px) scale(0.92) translateZ(-50px)`;
    }
  };

  // Enhanced value props data with images, icons, and how it works features
  const valueProps = [
    {
      title: "Never Miss a New Patient",
      description: "AI-powered after-hours call handling ensures you capture every new lead, even when your office is closed.",
      highlightWords: ["Never Miss", "New Patient"],
      stat: "100%",
      statLabel: "Call Answer Rate",
      color: "#26add0",
      image: NewPatientImage,
      showProgressBar: false,
      assistantId: "720fcf92-2fe9-45d0-8f46-cc8d94afbdb8", 
      features: [
        "<strong>AI answers calls 24/7</strong> – Handles new patient inquiries, books appointments, and collects details.",
        "<strong>Instant follow-up texts</strong> – If a call is missed, AI texts the caller to capture the lead.",
        "<strong>Office staff gets notified</strong> – Ensures no potential patient goes unnoticed."
      ]
    },
    {
      title: "Increase Patient Bookings & Retention",
      description: "Encourage consistent scheduling and make it easy for patients to book appointments.",
      highlightWords: ["Increase Patient Bookings", "Retention"],
      stat: "85%",
      statLabel: "Booking Rate",
      color: "#26add0",
      image: RoutineImage,
      showProgressBar: true,
      assistantId: "c5a09b1c-77f0-46c9-914f-4113d222f3f4", 
      progressType: "increase",
      features: [
        "<strong>AI tracks patient history</strong> – Knows when they're due for a cleaning or treatment.",
        "<strong>Automated appointment reminders</strong> – AI calls or texts patients when it's time to schedule.",
        "<strong>AI books available time slots</strong> – Finds the best available appointment and confirms it with the patient, collecting all necessary details."
      ]
    },
    {
      title: "Minimize No-Shows & Recover Missed Appointments",
      description: "Keep your schedule running smoothly with our automated patient communication system.",
      highlightWords: ["Minimize", "Recover"],
      stat: "30%",
      statLabel: "Reduction in No-Shows",
      color: "#32c5e6",
      image: AppointmentReminderImage,
      showProgressBar: true,
      assistantId:"f371d381-9358-4cbf-8beb-d9605f0e4226",
      progressType: "decrease",
      features: [
        "<strong>AI sends automated reminders</strong> – Patients get voice or text confirmations before their appointment.",
        "<strong>Pre-appointment readiness check</strong> – Ensures patients complete any required paperwork or prep.",
        "<strong>Smart rescheduling options</strong> – If a patient cancels or no-shows, AI suggests a new time and books it automatically by text or voice"
      ]
    },
    {
      title: "Enhance Patient Satisfaction & Boost Reviews",
      description: "Build a stronger reputation by systematically collecting feedback and encouraging reviews.",
      highlightWords: ["Enhance Patient Satisfaction", "Boost Reviews"],
      stat: "94%",
      statLabel: "Patient Satisfaction",
      color: "#3edcf7",
      image: CustomerSatisfaction,
      showProgressBar: false,
      assistantId: "cd268536-f794-44e0-9a06-ce500fbbf2ae",
      features: [
        "<strong>AI sends automated post-visit check-ins</strong> – Ensures patients are happy with their care.",
        "<strong>Handles common patient questions</strong> – Reduces calls to the front desk.",
        "<strong>Smart review & referral requests</strong> – AI encourages satisfied patients to leave reviews or refer friends."
      ]
    }
  ];

  // Move indicator when active index changes
  useEffect(() => {
    if (indicatorRef.current && navItemsRef.current[activeIndex]) {
      const navItem = navItemsRef.current[activeIndex];
      const navItemRect = navItem.getBoundingClientRect();
      
      // Update indicator position and width
      indicatorRef.current.style.left = `${navItem.offsetLeft}px`;
      indicatorRef.current.style.width = `${navItemRect.width}px`;
      
      // Center the active nav item in the scroll area
      const navContainer = navItem.parentElement;
      navContainer.scrollLeft = navItem.offsetLeft - (navContainer.offsetWidth / 2) + (navItemRect.width / 2);
    }
  }, [activeIndex]);

  // Section visibility detection
  useEffect(() => {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      sectionObserver.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        sectionObserver.unobserve(sectionRef.current);
      }
    };
  }, []);

  // Setup keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
        e.preventDefault();
        setActiveIndex(prev => Math.min(prev + 1, valueProps.length - 1));
      } else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
        e.preventDefault();
        setActiveIndex(prev => Math.max(prev - 1, 0));
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [valueProps.length]);

  // Custom wheel event handler to control card navigation
  useEffect(() => {
    const handleWheel = (e) => {
      // Check if the pointer is within the cards container
      if (cardsContainerRef.current && cardsContainerRef.current.contains(e.target)) {
        e.preventDefault();
        
        // Debounce wheel events
        if (!cardsContainerRef.current.wheelTimeout) {
          cardsContainerRef.current.wheelTimeout = setTimeout(() => {
            delete cardsContainerRef.current.wheelTimeout;
          }, 150); // Adjust debounce time as needed
          
          // Determine scroll direction
          if (e.deltaY > 0) {
            // Scroll down
            setActiveIndex(prev => Math.min(prev + 1, valueProps.length - 1));
          } else {
            // Scroll up
            setActiveIndex(prev => Math.max(prev - 1, 0));
          }
        }
      }
    };
    
    // Add passive: false to prevent default scrolling in modern browsers
    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => window.removeEventListener('wheel', handleWheel, { passive: false });
  }, [valueProps.length]);
  
  // Programmatically switch to a card
  const goToCard = (index) => {
    if (index >= 0 && index < valueProps.length) {
      setActiveIndex(index);
    }
  };

  // Function to highlight specific words in the title
  const renderHighlightedTitle = (title, highlightWords, color) => {
    if (!highlightWords || highlightWords.length === 0) return title;
    
    let result = title;
    highlightWords.forEach(word => {
      const regex = new RegExp(`(${word})`, 'gi');
      result = result.replace(regex, `<highlight>$1</highlight>`);
    });
    
    const parts = result.split(/<highlight>|<\/highlight>/);
    
    return parts.map((part, index) => {
      // Every odd index is a highlighted part
      return index % 2 === 1 ? 
        <HighlightText key={index} color={color}>{part}</HighlightText> : 
        <span key={index}>{part}</span>;
    });
  };

  return (
    <section 
      ref={sectionRef} 
      className="relative py-16 bg-gradient-to-b from-white to-blue-50 pb-32"
    >
      {/* Decorative elements */}
      <div 
        className="absolute top-20 left-1/4 w-24 h-24 bg-[#26add0] bg-opacity-5 rounded-full" 
        style={{animation: "5s ease-in-out 0s infinite alternate none running floating1"}}
      ></div>
      <div 
        className="absolute bottom-20 right-1/4 w-16 h-16 bg-[#26add0] bg-opacity-8 rounded-full" 
        style={{animation: "7s ease-in-out 1s infinite alternate none running floating2"}}
      ></div>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        {/* Navigation bar */}
        <div className="sticky top-4 z-20 mb-10 bg-white bg-opacity-90 backdrop-blur-sm rounded-full shadow-md">
          <div className="relative flex justify-center overflow-x-auto hide-scrollbar py-2 px-4">
            {valueProps.map((prop, index) => {
              // Shorter nav text versions
              const shortNavText = [
                "New Patients",
                "Increase Bookings",
                "Reduce No-Shows",
                "Boost Reviews",
              ][index];
              
              return (
                <button
                  key={index}
                  ref={el => navItemsRef.current[index] = el}
                  onClick={() => goToCard(index)}
                  className={`whitespace-nowrap px-4 py-2 text-center transition-all duration-300 ${
                    activeIndex === index 
                      ? 'text-[#26add0] font-medium' 
                      : 'text-gray-500 hover:text-gray-800'
                  }`}
                >
                  {shortNavText}
                </button>
              );
            })}
            {/* Moving indicator */}
            <div 
              ref={indicatorRef}
              className="absolute bottom-0 h-0.5 bg-[#26add0] transition-all duration-300"
              style={{ left: 0, width: 0 }}
            ></div>
          </div>
        </div>
        
        {/* Card stack with 3D stacking effect */}
        <div 
          ref={cardsContainerRef}
          className={`relative h-[650px] transform transition-all duration-500 perspective-1000 ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
          style={{ transformStyle: 'preserve-3d' }}
        >
          {valueProps.map((prop, index) => {
            const isActive = index === activeIndex;
            const distance = Math.abs(index - activeIndex);
            
            return (
              <div 
                key={index}
                className="absolute top-0 left-0 w-full h-full transition-all duration-500"
                style={{
                  opacity: getCardOpacity(index, activeIndex),
                  transform: getCardTransform(index, activeIndex),
                  zIndex: isActive ? 1000 : valueProps.length - distance,
                  pointerEvents: distance <= 1 ? 'auto' : 'none',
                }}
              >
                <div 
                  className={`w-full h-full rounded-3xl shadow-lg bg-white overflow-hidden relative ${
                    isActive ? '' : 'card-background'
                  }`}
                  style={{
                    boxShadow: isActive 
                      ? '0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.05)'
                      : 'none',
                  }}
                >
                  {/* Background pattern */}
                  <div className="absolute inset-0 z-0 opacity-5">
                    <div className="absolute -right-10 -top-10 w-64 h-64 bg-[#26add0] rounded-full"></div>
                    <div className="absolute -left-20 -bottom-20 w-80 h-80 bg-[#3edcf7] rounded-full"></div>
                  </div>
                  
                  {/* Render active card content */}
                  {isActive && (
                    <div className="h-full relative z-10">
                      <div className="flex flex-col h-full">
                      
                        {/* Main content grid - Fixed to remove scrolling issues */}
                        <div className="grid grid-cols-1 md:grid-cols-2 h-full">
                          {/* Left column: Content - With consistent padding and flex structure */}
                          <div className="flex flex-col p-8 h-full justify-between">
                            {/* Card title with highlighted words */}
                            <div className="mb-4">
                              <h3 className="text-3xl font-bold text-gray-800 leading-tight mb-2">
                                {renderHighlightedTitle(prop.title, prop.highlightWords, prop.color)}
                              </h3>
                              <p className="text-lg text-gray-600">{prop.description}</p>
                            </div>
                            
                            {/* How it Works section - Adjusted spacing */}
                            <div className="mt-2 mb-4">
                              <h4 className="text-xl font-semibold text-gray-700 mb-2">How it Works:</h4>
                              <div className="space-y-2">
                                {prop.features.map((feature, idx) => (
                                  <FeaturePoint 
                                    key={idx}
                                    index={idx}
                                    text={feature}
                                    color={prop.color}
                                  />
                                ))}
                              </div>
                            </div>

                            {/* Conditional progress animation - Reduced margin */}
                            {prop.showProgressBar && (
                              <div className="mt-4 animate-fadeIn">
                                <ProgressBar type={prop.progressType} color={prop.color} />
                              </div>
                            )}
                            
                            {prop.assistantId && (
                            <div className="mt-8 animate-fadeIn mb-16"> 
                              {(index === 1 || index === 2 || index === 3) ? (
                                <div className="flex justify-center">
                                  <div className="flex flex-wrap justify-center gap-3">
                                    {/* Call Button */}
                                    <VapiContactButton 
                                      assistantId={prop.assistantId}
                                      color={prop.color}
                                      title={prop.title}
                                      mode="call"
                                    />
                                    
                                    {/* Text Button - Conditionally render except for index 0 */}
                                    {index !== 0 && (
                                      <VapiContactButton 
                                        assistantId={prop.assistantId}
                                        color={lightenColor(prop.color, 20)}
                                        title={prop.title}
                                        mode="text"
                                      />
                                    )}
                                  </div>
                                </div>
                              ) : (
                                /* For the first card */
                                <div className="flex justify-center">
                                  <div className="flex flex-row flex-wrap items-center justify-center gap-3 mb-8">
                                    <WebWidgetButton 
                                      color="#5ACFC9" 
                                      title={prop.title}
                                      initialMessage="Hi there! How can I help you today?"
                                    />
                                    
                                    <VapiWebTestButton 
                                      assistantId={prop.assistantId}
                                      color={prop.color}
                                      title={prop.title}
                                    />
                                    
                                    {/* Call Button */}
                                    <VapiContactButton 
                                      assistantId={prop.assistantId}
                                      color={prop.color}
                                      title={prop.title}
                                      mode="call"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}



                            {/* Simplified indicator at the bottom - with fixed positioning to avoid spacing issues */}
                            <div 
                              className="absolute left-8 flex items-center gap-2 text-xs text-gray-400" 
                              style={{ bottom: '1rem' }}
                            >
                              <span className="w-2 h-2 rounded-full bg-green-500 animate-pulse"></span>
                              <span>Scroll or use arrow keys</span>
                            </div>
                            </div>
                          
                          {/* Right column: Image - Fixed height and eliminated scrolling */}
                          <div className="relative flex items-center justify-center bg-white h-full">
                            <div className="absolute top-1/2 left-0 w-full h-px bg-gray-100"></div>
                            
                            <div className="flex items-center justify-center relative z-10 p-4">
                              <div className="transform transition-all duration-300 hover:scale-105">
                                {/* Adjusted image container size to prevent scrolling */}
                                <div className="relative">
                                  {/* Back overlay in different color to make image stand out */}
                                  <div 
                                    className="absolute -right-4 -bottom-4 rounded-xl"
                                    style={{ 
                                      width: "360px", 
                                      height: "540px", // Slightly reduced height
                                      backgroundColor: lightenColor(prop.color, 20),
                                      opacity: 0.5,
                                      zIndex: 1
                                    }}
                                  ></div>
                                  
                                  {/* Adjusted image container size */}
                                  <div 
                                    className="overflow-hidden"
                                    style={{ 
                                      width: "360px", 
                                      height: "540px", // Slightly reduced height
                                      borderRadius: "1.5rem",
                                      boxShadow: `0 15px 25px rgba(0,0,0,0.08), 0 0 0 2px ${prop.color}22`,
                                      position: "relative",
                                      zIndex: 10
                                    }}
                                  >
                                    <img 
                                      src={prop.image} 
                                      alt={prop.title}
                                      className="w-full h-full object-contain"
                                      loading="eager"
                                    />
                                  </div>
                                  <div 
                                    className="absolute -bottom-2 -right-2 w-12 h-12 rounded-full"
                                    style={{ 
                                      background: prop.color,
                                      opacity: 0.7,
                                      zIndex: 20
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>                
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {/* Bottom accent */}
                  <div 
                    className="absolute bottom-0 left-0 h-1 transition-all duration-500 w-full"
                    style={{ 
                      background: `linear-gradient(to right, ${prop.color}, ${lightenColor(prop.color)})`,
                      opacity: isActive ? 1 : 0.3,
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
          
          {/* Card navigation indicators */}
          <div className="absolute -bottom-12 left-0 right-0 flex justify-center gap-3">
            {valueProps.map((_, index) => (
              <button
                key={index}
                onClick={() => goToCard(index)}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${
                  activeIndex === index 
                    ? 'bg-[#26add0] w-8' 
                    : 'bg-gray-300 hover:bg-gray-400'
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      
      {/* CSS styles moved to valueprops-styles.css */}
      
      {/* Bottom wave decoration */}
      <div className="absolute bottom-0 left-0 w-full overflow-hidden" style={{ height: "70px" }}>
        <svg 
          viewBox="0 0 1200 120" 
          preserveAspectRatio="none" 
          className="absolute bottom-0 left-0 w-full h-full" 
          style={{ transform: 'rotate(180deg)' }}
        >
          <path 
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" 
            fill="#ffffff"
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default ValuePropsSection;