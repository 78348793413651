import React, { useState, useEffect, forwardRef } from "react";
import "../styles/PhoneCallSection.css";


const API_ROUTES = {
    CALLS: '/api/v1/calls/',
    SMS: '/api/v1/outbound-sms/'
  };
  

const PhoneCallSection = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    dentalOfficeName: "",
    appointmentDate: "", // Added for text mode
    appointmentTime: "", // Added for text mode
    isLoading: false,
    success: false,
    error: null,
  });

  // Capture formProps from window state if present
  const [formProps, setFormProps] = useState({
    assistantId: "720fcf92-2fe9-45d0-8f46-cc8d94afbdb8", 
    phoneNumberId: "644d949b-65e7-4ad3-93ce-9be20a620006",
    color: "#26add0",
    title: "Request a Phone Call",
    mode: "call" // Default to call mode
  });
  
  // Check if in text mode
  const isTextMode = formProps.mode === "text";

  // Function to check and load form props from session storage
  const loadFormPropsFromStorage = () => {
    const storedProps = sessionStorage.getItem("callFormProps");
    if (storedProps) {
      try {
        const parsedProps = JSON.parse(storedProps);
        console.log("Retrieved form props:", parsedProps); // Debug log
        setFormProps({
          ...formProps,
          ...parsedProps
        });
        // Clear after retrieval
        sessionStorage.removeItem("callFormProps");
      } catch (e) {
        console.error("Error parsing stored props:", e);
      }
    }
  };

  // Load form props on initial mount
  useEffect(() => {
    loadFormPropsFromStorage();
    
    // Also listen for the custom event from the button component
    const handleModeChange = (event) => {
      console.log("Contact mode changed event received:", event.detail);
      setFormProps({
        ...formProps,
        ...event.detail.formProps
      });
    };
    
    window.addEventListener('contactModeChanged', handleModeChange);
    
    return () => {
      window.removeEventListener('contactModeChanged', handleModeChange);
    };
  }, []);

  const [validation, setValidation] = useState({
    name: true,
    phoneNumber: true,
    dentalOfficeName: true,
    appointmentDate: true,
    appointmentTime: true
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear validation error when typing
    if (!validation[name]) {
      setValidation((prev) => ({
        ...prev,
        [name]: true,
      }));
    }
  };

  const validateForm = () => {
    const phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    const newValidation = {
      name: formData.name.trim().length > 0,
      phoneNumber: phoneRegex.test(formData.phoneNumber),
      dentalOfficeName: formData.dentalOfficeName.trim().length > 0,
      // Removed date and time validation as they're not required for texting
    };

    setValidation(newValidation);
    return Object.values(newValidation).every((valid) => valid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setFormData((prev) => ({ ...prev, isLoading: true, error: null }));

    try {
      // Format phone number for API
      const formattedPhoneNumber = formData.phoneNumber
        .replace(/\D/g, '')         // Remove non-digits
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '+1$1$2$3'); // Format as +1XXXXXXXXXX

      if (isTextMode) {
        // Determine which prompt type to use based on the assistantId
        let promptType = "outbound_post_appointment"; // Default

        // Map assistantId to the correct prompt type
        switch (formProps.assistantId) {
          case "c5a09b1c-77f0-46c9-914f-4113d222f3f4": // 2nd card
            promptType = "outbound_new_appointment";
            break;
          case "f371d381-9358-4cbf-8beb-d9605f0e4226": // 3rd card
            promptType = "outbound_appointment_confirmation";
            break;
          case "cd268536-f794-44e0-9a06-ce500fbbf2ae": // 4th card
            promptType = "outbound_post_appointment";
            break;
          default:
            promptType = "outbound_post_appointment";
        }

        console.log("Using prompt type:", promptType);

        // Handle text message request
        const textRequestBody = {
          request: {
            phone_number: formattedPhoneNumber,
            prompt_type: promptType,
            variables: {
              clientName: formData.name,
              practiceName: formData.dentalOfficeName || "Sunshine Dental Care"
              // Removed date and time as they're not required
            }
          }
        };

        console.log("Sending text message with data:", textRequestBody);

        // Make API call to send text message
        const response = await fetch(`${process.env.REACT_APP_OPERAITOR_URL}${API_ROUTES.SMS}`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              'X-API-Key': process.env.REACT_APP_X_API_KEY // Changed from API_KEY to X_API_KEY
            },
            body: JSON.stringify(textRequestBody)
          });

        const responseData = await response.json();
        
        if (!response.ok) {
          console.error("API Error Response:", responseData);
          let errorMessage = "An unexpected error occurred. Please try again later.";
          throw new Error(errorMessage);
        }
        
        console.log("Text message sent successfully:", responseData);

      } else {
        // Handle phone call request - original functionality
        // Prepare variable values for the assistant
        const variableValues = {
          clientName: formData.name,
          practiceName: formData.dentalOfficeName,
        };

        const callRequestBody = {
          customer: {
            name: formData.name,
            number: formattedPhoneNumber
          },
          assistant_id: formProps.assistantId,
          phoneNumberId: formProps.phoneNumberId,
          name: formData.name ? `Call to ${formData.name}` : `Call to Dental Client`,
          metadata: {
            dental_office_name: formData.dentalOfficeName,
            source: "website_form"
          },
          variable_values: variableValues
        };
        
        // Wrap it in the call_request object as expected by the backend
        const requestBody = {
          call_request: callRequestBody
        };
        
        // Make API call to initiate phone call
        const response = await fetch(`${process.env.REACT_APP_OPERAITOR_URL}${API_ROUTES.CALLS}`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              'X-API-Key': process.env.REACT_APP_X_API_KEY // Note the change from API_KEY to X_API_KEY
            },
            body: JSON.stringify(requestBody)
          });
        
        const responseData = await response.json();
        
        if (!response.ok) {
          console.error("API Error Response:", responseData);
          let errorMessage = "We couldn't initiate your call. Please try again.";
          if (responseData.detail) {
            errorMessage = typeof responseData.detail === 'string' 
              ? responseData.detail 
              : Array.isArray(responseData.detail)
                ? responseData.detail.map(err => `${err.loc.join('.')} - ${err.msg}`).join('; ')
                : "An error occurred";
          }
          throw new Error(errorMessage);
        }
        
        console.log("Call initiated successfully:", responseData);
      }

      setFormData((prev) => ({ ...prev, isLoading: false, success: true }));

      // Reset the form after a delay
      setTimeout(() => {
        resetForm();
      }, 5000);
    } catch (error) {
      console.error(`Error ${isTextMode ? 'sending text' : 'initiating call'}:`, error);
      setFormData((prev) => ({
        ...prev,
        isLoading: false,
        error: typeof error.message === 'string' 
          ? error.message 
          : `We couldn't ${isTextMode ? 'send your text' : 'initiate your call'}. Please try again.`,
      }));
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      phoneNumber: "",
      dentalOfficeName: "",
      appointmentDate: "",
      appointmentTime: "",
      isLoading: false,
      success: false,
      error: null,
    });
    setValidation({
      name: true,
      phoneNumber: true,
      dentalOfficeName: true,
      appointmentDate: true,
      appointmentTime: true
    });
  };

  const formatPhoneNumber = (phoneNum) => {
    const cleaned = ('' + phoneNum).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNum;
  };

  // Add keyframe animation to CSS
  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      @keyframes gradientShift {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
      }
    `;
    document.head.appendChild(styleTag);
    
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  // Extract UI components for cleaner rendering
  const ErrorMessage = ({ field }) => (
    <p className="form-error">
      <svg xmlns="http://www.w3.org/2000/svg" className="error-icon" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
      </svg>
      {field === 'name' && 'Please enter your name'}
      {field === 'phoneNumber' && 'Please enter a valid phone number'}
      {field === 'dentalOfficeName' && 'Please enter your dental office name'}
      {field === 'appointmentDate' && 'Please enter an appointment date'}
      {field === 'appointmentTime' && 'Please enter an appointment time'}
    </p>
  );

  const SuccessMessage = () => (
    <div className="success-container">
      <div className="success-icon-container">
        <svg xmlns="http://www.w3.org/2000/svg" className="success-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg>
      </div>
      <h4 className="success-title">
        {isTextMode ? "Text Message Sent!" : "Call Initiated!"}
      </h4>
      <p className="success-message">
        {isTextMode 
          ? `We've sent a text message to ${formatPhoneNumber(formData.phoneNumber)}`
          : `We're calling you right now at ${formatPhoneNumber(formData.phoneNumber)}`}
      </p>
      <div className="success-divider"></div>
      <p className="success-reset-message">
        This form will reset in a few seconds so you can request {isTextMode ? "another text" : "another call"} if needed.
      </p>
    </div>
  );

  return (
    <section 
      ref={ref} 
      id="phone-call-section"
      className="phone-call-section"
    >
      {/* Decorative circles */}
      <div className="decorative-circle circle-1"></div>
      <div className="decorative-circle circle-2"></div>
      
      <div className="container">
        <div className="section-header">
          <h2 className="section-title">
            {isTextMode ? "Try Our AI Text Assistant" : "Try Our AI Phone Assistant"}
            <span className="title-underline"></span>
          </h2>
          <p className="section-description">
            {isTextMode
              ? "Fill out this form and our AI will send you a text message to demonstrate how it handles appointment scheduling, answers questions, and captures information for your practice."
              : "Fill out this form and our AI will call you to demonstrate how it handles appointment scheduling, answers questions, and captures information for your practice."}
          </p>
        </div>

        <div className="form-card">
          {/* Simple background pattern */}
          <div className="bg-pattern pattern-1"></div>
          <div className="bg-pattern pattern-2"></div>
          
          {/* Header */}
          <div 
            className="form-header"
            style={{ 
              background: `linear-gradient(135deg, ${formProps.color}, ${formProps.color}aa, ${formProps.color})`,
            }}
          >
            <h3 className="form-header-title">Get Started Now</h3>
            {/* Sparkles */}
            <svg className="hidden" aria-hidden="true">
              <defs>
                <filter id="glow">
                  <feGaussianBlur stdDeviation="2.5" result="coloredBlur" />
                  <feMerge>
                    <feMergeNode in="coloredBlur" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
            </svg>
            
            {/* Wave pattern */}
            <div className="wave-container">
              <svg 
                viewBox="0 0 1200 120" 
                preserveAspectRatio="none" 
                className="wave-svg" 
              >
                <path 
                  d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V120H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" 
                  fill="white"
                ></path>
              </svg>
            </div>
          </div>

          {/* Form content */}
          <div className="form-content">
            {/* Separator */}
            <div className="form-separator"></div>
            
            {formData.success ? (
              <SuccessMessage />
            ) : (
              <form onSubmit={handleSubmit} className="call-form">
                {formData.error && (
                  <div className="form-error-message">
                    <svg xmlns="http://www.w3.org/2000/svg" className="error-message-icon" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                    {formData.error}
                  </div>
                )}

                <div className="form-grid">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Your Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className={`form-input ${!validation.name ? 'input-error' : ''}`}
                      placeholder="Enter your full name"
                      disabled={formData.isLoading}
                    />
                    {!validation.name && <ErrorMessage field="name" />}
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      className={`form-input ${!validation.phoneNumber ? 'input-error' : ''}`}
                      placeholder="(123) 456-7890"
                      disabled={formData.isLoading}
                    />
                    {!validation.phoneNumber && <ErrorMessage field="phoneNumber" />}
                  </div>
                </div>
                
                <div className="form-group-full">
                  <label htmlFor="dentalOfficeName" className="form-label">
                    Dental Office Name
                  </label>
                  <input
                    type="text"
                    id="dentalOfficeName"
                    name="dentalOfficeName"
                    value={formData.dentalOfficeName}
                    onChange={handleChange}
                    className={`form-input ${!validation.dentalOfficeName ? 'input-error' : ''}`}
                    placeholder="Enter your practice name"
                    disabled={formData.isLoading}
                  />
                  {!validation.dentalOfficeName && <ErrorMessage field="dentalOfficeName" />}
                </div>
                
                {/* Removed additional date/time fields for text mode */}
                
                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={formData.isLoading}
                  className="submit-button"
                  style={{ 
                    background: `linear-gradient(to right, ${formProps.color}, ${formProps.color}dd)`,
                    marginBottom: "4rem" // Significantly increased bottom padding
                  }}
                >
                  <span className="button-content">
                    {formData.isLoading ? (
                      <>
                        <svg className="loading-spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="spinner-track" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="spinner-path" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span className="button-text">
                          {isTextMode ? "Sending Text..." : "Initiating Call..."}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="button-icon-container">
                          {isTextMode ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="button-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                            </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" className="button-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>
                          )}
                        </span>
                        <span className="button-text">
                          {isTextMode ? "Send Me a Text" : "Call Me Now"}
                        </span>
                      </>
                    )}
                  </span>
                </button>
                
                <p className="form-disclaimer">
                  By clicking "{isTextMode ? "Send Me a Text" : "Call Me Now"}", you agree to our Terms of Service and Privacy Policy.
                  We'll only use your information to contact you about our services.
                </p>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
});

export default PhoneCallSection;