import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import homeSvg from "../assets/home-svg.png";
import "../styles/animation.css"
import VapiCallTestButton from "./ContactButton";


const thumbAlt = "Healthcare Communication Platform";

const TitleSection = React.forwardRef((props, ref) => {
  const navigate = useNavigate();


  const handleShowForm = () => {
    // Store the props in sessionStorage
    const props = { 
      assistantId: "720fcf92-2fe9-45d0-8f46-cc8d94afbdb8",
      color: "#4B5563",
      title: "Try It Now"
    };
    sessionStorage.setItem("callFormProps", JSON.stringify(props));
    
    // Scroll to the phone call section
    const phoneCallSection = document.getElementById("phone-call-section");
    if (phoneCallSection) {
      phoneCallSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <section ref={ref} className="relative min-h-screen flex items-center">
      {/* Background SVG - keeping original background */}
      
      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 w-full">
        <div className="h-full flex items-center py-12 md:py-16">
          {/* Top decorative elements */}
          <div className="absolute top-20 left-1/4 w-24 h-24 bg-[#26add0] bg-opacity-5 rounded-full" style={{animation: "3s ease-in-out 0s infinite alternate none running floating1"}}></div>
          <div className="absolute top-24 right-1/4 w-16 h-16 bg-[#26add0] bg-opacity-8 rounded-full" style={{animation: "6s ease-in-out 1s infinite alternate none running floating2"}}></div>
          {/* Two column layout: text on left, image on right */}
          <div className="flex flex-col md:flex-row items-center">
            {/* Left column - Text content */}
            <div className="md:w-1/2 md:pr-8 text-left relative">
              {/* Decorative elements extending from the right side */}
              <div className="hidden md:block absolute -right-16 top-1/4 w-32 h-32 bg-[#26add0] bg-opacity-5 rounded-full" style={{animation: "8s ease-in-out 0s infinite alternate none running floating2"}}></div>
              <div className="hidden md:block absolute -right-8 bottom-1/3 w-16 h-16 bg-[#26add0] bg-opacity-8 rounded-full" style={{animation: "5s ease-in-out 1s infinite alternate none running floating3"}}></div>
              
              {/* Heading with larger size */}
              <h1
                className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-6 relative"
                data-aos="zoom-y-out"
              >
                <div className="relative">
                  <div className="absolute -top-16 left-0 w-20 h-20 bg-[#26add0] bg-opacity-5 rounded-full" style={{animation: "5s ease-in-out 0s infinite alternate none running floating2"}}></div>
                  <div className="absolute -top-10 left-24 w-10 h-10 bg-[#26add0] bg-opacity-10 rounded-full" style={{animation: "4s ease-in-out 0.5s infinite alternate none running floating3"}}></div>
                  Better <span className="text-[#26add0] bg-clip-text">Care</span> Begins With Better <span className="text-[#26add0] bg-clip-text">Communication</span>
                </div>
              </h1>
              
              {/* Subheadline with increased size */}
              <p
                className="text-2xl text-gray-600 mb-8 italic relative"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Your AI agent to book more patients, increase customer satisfaction, and support your front desk — without missing a single call.
              </p>
              
              {/* CTA Buttons */}
              <div className="flex mb-8">
                <button
                  onClick={() => window.open("https://calendly.com/johnlim0043/operaitor-demo", "_blank")}
                  className="bg-[#26add0] hover:bg-[#5bc1d5] text-white font-bold py-3 px-10 rounded-lg mr-4"
                >
                  Book a Demo
                </button>
                <button
                  onClick={handleShowForm}
                  className="border border-gray-400 text-gray-700 font-bold py-3 px-10 rounded-lg hover:bg-gray-100"
                >
                  Try It Now
                </button>
              </div>
            </div>
            
            {/* Right column - Stylized image */}
            <div className="md:w-1/2 mt-8 md:mt-0" data-aos="zoom-y-out" data-aos-delay="450">
              <div className="relative">
                {/* Main image with enhanced styling */}
                <div className="transform transition-all duration-300 hover:scale-105">
                  <div className="relative">
                    {/* Background shape with animation for visual interest */}
                    <div className="absolute -top-4 -right-4 w-full h-full bg-gradient-to-br from-blue-100 to-teal-100 rounded-lg" style={{animation: "4s cubic-bezier(0.4, 0, 0.6, 1) 0s infinite normal none running pulsing"}}></div>
                    
                    {/* Floating decorative elements */}
                    <div className="absolute -top-8 right-16 w-12 h-12 bg-[#26add0] bg-opacity-10 rounded-full" style={{animation: "7s ease-in-out 0s infinite alternate none running floating1"}}></div>
                    <div className="absolute top-1/3 -left-6 w-8 h-8 bg-[#26add0] bg-opacity-20 rounded-full" style={{animation: "6s ease-in-out 1s infinite alternate none running floating2"}}></div>
                    <div className="absolute bottom-12 right-1/4 w-6 h-6 bg-[#26add0] bg-opacity-15 rounded-full" style={{animation: "5s ease-in-out 0.5s infinite alternate none running floating3"}}></div>
                    
                    {/* Decorative dots pattern */}
                    <div className="absolute -bottom-3 -left-3 w-24 h-24 bg-[#26add0] bg-opacity-10 rounded-full"></div>
                    <div className="absolute top-1/4 -right-4 w-16 h-16 bg-[#26add0] bg-opacity-20 rounded-full"></div>
                    
                    {/* Main image with slightly imperfect circular shape */}
                    <img
                      src={homeSvg}
                      alt={thumbAlt}
                      className="relative z-10 transform transition-all duration-300 hover:scale-105"
                      style={{
                        boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
                        border: "1px solid rgba(226, 232, 240, 0.7)",
                        borderRadius: "75% 65% 70% 60% / 65% 70% 60% 75%",
                        filter: "drop-shadow(0 0 10px rgba(38, 173, 208, 0.3))",
                      }}
                    />
                    
                    {/* Subtle glow effect */}
                    <div 
                      className="absolute inset-0 z-0 opacity-70" 
                      style={{
                        borderRadius: "75% 65% 70% 60% / 65% 70% 60% 75%",
                        background: "radial-gradient(circle at center, rgba(38, 173, 208, 0.2) 0%, rgba(38, 173, 208, 0) 70%)",
                        transform: "scale(1.1)",
                        filter: "blur(15px)",
                      }}
                    ></div>
                    
                    {/* Highlight accent */}
                    <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-3/4 h-1 bg-[#26add0] rounded-full"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

// Add display name for debugging purposes
TitleSection.displayName = 'TitleSection';

export default TitleSection;