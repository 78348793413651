import React, { useState, useEffect } from 'react';
import Vapi from "@vapi-ai/web";

const VapiWebTestButton = ({ assistantId, color, title }) => {
  const [callStatus, setCallStatus] = useState('idle'); // idle, connecting, active, ended
  const [isMuted, setIsMuted] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [vapiInstance, setVapiInstance] = useState(null);

  // Initialize Vapi
  useEffect(() => {
    const vapiPublicKey = process.env.REACT_APP_VAPI_API_KEY || process.env.VITE_VAPI_API_KEY;
    
    if (!vapiPublicKey) {
      console.error("Vapi API key not found in environment variables. Please add REACT_APP_VAPI_API_KEY or VITE_VAPI_API_KEY to your .env file.");
      return;
    }
    
    const vapi = new Vapi(vapiPublicKey);

    // Set up event listeners
    vapi.on("call-start", () => {
      setCallStatus('active');
      console.log("Call has started");
    });

    vapi.on("call-end", () => {
      setCallStatus('ended');
      console.log("Call has ended");
      
      // Reset after a delay
      setTimeout(() => {
        setCallStatus('idle');
      }, 3000);
    });

    vapi.on("speech-start", () => {
      console.log("Assistant started speaking");
    });

    vapi.on("speech-end", () => {
      console.log("Assistant stopped speaking");
    });

    vapi.on("volume-level", (volume) => {
      setVolumeLevel(volume);
    });

    vapi.on("error", (error) => {
      console.error("Vapi error:", error);
      setCallStatus('idle');
    });

    vapi.on("message", (message) => {
      console.log("Received message:", message);
    });

    setVapiInstance(vapi);

    // Cleanup on component unmount
    return () => {
      if (vapi) {
        vapi.stop();
      }
    };
  }, []);

  // Handle starting a call
  const startCall = async () => {
    if (!vapiInstance || callStatus === 'active' || callStatus === 'connecting') {
      return;
    }

    try {
      setCallStatus('connecting');
      
      // You can either use a pre-created assistant ID
      if (assistantId) {
        await vapiInstance.start(assistantId);
      } else {
        // Or create an assistant configuration based on the current value prop
        await vapiInstance.start({
          model: {
            provider: "openai",
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content: `You are a helpful assistant demonstrating the "${title}" feature. Keep your responses focused on this particular feature and how it helps dental practices.`,
              },
            ],
          },
          voice: {
            provider: "playht",
            voiceId: "jennifer",
          },
          name: `${title} Demo`,
          transcriber: {
            provider: "deepgram",
            model: "nova-2",
            language: "en-US",
          },
        });
      }
    } catch (error) {
      console.error("Failed to start call:", error);
      setCallStatus('idle');
    }
  };

  // Handle ending a call
  const endCall = () => {
    if (vapiInstance && callStatus === 'active') {
      vapiInstance.stop();
      setCallStatus('ending');
    }
  };

  // Toggle mute
  const toggleMute = () => {
    if (vapiInstance && callStatus === 'active') {
      const newMuteState = !isMuted;
      vapiInstance.setMuted(newMuteState);
      setIsMuted(newMuteState);
    }
  };

  return (
    <div className="flex flex-col items-center">
      {/* Main action button */}
      <button
        onClick={callStatus === 'active' ? endCall : startCall}
        disabled={callStatus === 'connecting' || callStatus === 'ending'}
        className={`relative rounded-full px-6 py-3 text-white font-medium transition-all duration-300 flex items-center gap-2 ${
          callStatus === 'active' 
            ? 'bg-red-500 hover:bg-red-600' 
            : callStatus === 'connecting' || callStatus === 'ending'
              ? 'bg-gray-400 cursor-not-allowed'
              : `hover:shadow-lg hover:scale-105 active:scale-95`
        }`}
        style={{ 
          backgroundColor: callStatus === 'active' 
            ? '#f44336' 
            : callStatus === 'connecting' || callStatus === 'ending'
              ? '#9e9e9e'
              : color
        }}
      >
        {/* Button icon */}
        {callStatus === 'connecting' || callStatus === 'ending' ? (
          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        ) : callStatus === 'active' ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
          </svg>
        )}

        {/* Button text */}
        {callStatus === 'connecting' ? 'Connecting...' : 
          callStatus === 'active' ? 'End Call' : 
          callStatus === 'ending' ? 'Ending...' : 
          callStatus === 'ended' ? 'Call Ended' : 'Instant AI Demo'}
      </button>

      {/* Call controls - only show when call is active */}
      {callStatus === 'active' && (
        <div className="mt-3 flex items-center gap-3 animate-fadeIn">
          {/* Mute button */}
          <button
            onClick={toggleMute}
            className={`p-2 rounded-full transition-all ${isMuted ? 'bg-gray-300' : 'bg-gray-200'}`}
            title={isMuted ? "Unmute" : "Mute"}
          >
            {isMuted ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
              </svg>
            )}
          </button>

          {/* Volume indicator */}
          <div className="flex items-center gap-1">
            <div className="w-20 h-2 bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full bg-green-500 transition-all duration-100"
                style={{ width: `${volumeLevel * 100}%` }}
              />
            </div>
          </div>
        </div>
      )}

      {/* Status indicator */}
      {(callStatus === 'active') && (
        <div className="mt-2 text-xs flex items-center gap-1 animate-fadeIn">
          <span className="w-2 h-2 rounded-full bg-green-500 animate-pulse"></span>
          <span className="text-gray-600">Active call</span>
        </div>
      )}
    </div>
  );
};

export default VapiWebTestButton;